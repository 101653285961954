.anagram-title,
.title,
.subtitle,
.propose-button,
.authorize-trade-button,
.execute-trade-button {
  font-family: "Fira Sans";
}

.anagram-title {
  font-weight: 700;
  font-size: 100px;
  padding-left: 20px;
  margin-block-start: 20px;
  margin-block-end: 0px;
}

.title {
  font-weight: 100;
  font-size: 36px;
  color: gray;
  padding-left: 20px;
  margin-block-start: 0px;
  margin-block-end: 50px;
  font-family: "Fira Sans Condensed";
}

.subtitle {
  font-weight: 100;
  font-size: 28px;
  color: darkgray;
  padding-left: 20px;
  margin-block-start: 0px;
  margin-block-end: 50px;
}

.subtitle-bold {
  line-height: 160%;
  font-weight: 600;
  color: gray;
  font-family: "Fira Sans Condensed";
}

.authorize-title-section {
  margin-block-end: 20px;
}

.portfolio-views {
  margin-top: 0px;
}

.your-portfolio-view {
  display: flex;
  justify-content: center;
}

.their-portfolio-view {
  display: flex;
  justify-content: center;
}

.propose-button,
.authorize-trade-button,
.execute-trade-button {
  width: 190px;
  margin-top: 15px;
  font-weight: 800;
  font-size: 28px;
  height: 50px;
  color: grey;
  border-radius: 0;
  background-color: #FFF;
  border: 0;
}

.propose-button {
  margin-left: calc(50% - 95px);
}

button.propose-button:hover,
button.authorize-trade-button:hover,
button.execute-trade-button:hover {
  color: black;
  cursor: pointer;
}

.load-trade-div {
  justify-content: center;
  display: flex;
}