h1,
h2,
h3 {
    font-weight: 200;
    font-family: "Fira Sans Condensed";
    font-size: 30px;
    padding-left: 20px;
    /* margin-block-start: 10px; */
    margin-block-end: 0px;
}

h1 {
    font-weight: 700;
    font-size: 100px;
    margin-block-start: 20px;
}