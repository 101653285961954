.sidebar-load-trade-div {
  width: 100%;
  justify-content: center;
  display: flex;
}

.load-trade-textfield,
.load-trade-button,
.list-item-text,
.list-item-text-lighter {
  font-family: "Fira Sans";
}

.load-trade-textfield {
  resize: none;
  height: 30px;
  font-size: 20px;
  font-weight: lighter;
  width: calc(100% - 90px);
  border-width: 0 0 0px;
  border-color: gray;
  background-color: #f2f2f2;
}

.load-trade-button {
  height: 30px;
  font-size: 20px;
  font-weight: bold;
  color: grey;
  border-radius: 0;
  border: 0;
  float: right;
  background: none;
  width: 85px;
  padding-left: 10px;
}

button.load-trade-button:hover {
  color: black;
  cursor: pointer;
}

.list-item-text {
  font-size: 28px;
  font-weight: 600;
}

.list-item-text-lighter {
  font-size: 20px;
  font-weight: 100;
}
