.displayimg {
  height: 60px;
  width: 60px;
  border-radius: 30%;
}

.itemWrap {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.displayimg {
  height: 60px;
  width: 60px;
  border-radius: 30%;
}

.name-text {
  font-weight: 200;
  font-family: "Fira Sans Condensed";
  font-size: 15px;
}

.amount-text {
  font-weight: 700;
  font-size: 18px;
}