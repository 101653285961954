body {
  /* background: radial-gradient(60% 90% at 30% 50%,#98C6EA 0,rgba(255,255,255,0) 100%); */
  background: #FFF;
}

.mintWalletView {
  padding-left: 2%;
  padding-right: 2%;
  float: left;
}

.assetdiv {
  overflow-y: auto;
  white-space: nowrap;
  margin: 25px;
}

.tokendiv {
  overflow-y: auto;
  white-space: nowrap;
  width: 800px;
  height: 500px;
}

.nftdiv {
  white-space: nowrap;
  margin: auto;
}

.assetDiv {
  font-weight: 700;
  font-size: 0.9285714285714286rem;
  font-family: Poppins, Arial;
  position: absolute;
  text-align: center;
  background: #9796f0;
  background: -webkit-linear-gradient(to right,
      #fbc7d4,
      #9796f0);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #fbc7d4,
      #9796f0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.assetDiv h1 {
  margin: auto;
  line-height: 51px;
  vertical-align: middle;
}

.swapDiv {
  font-weight: 700;
  font-size: 0.9285714285714286rem;
  font-family: Poppins, Arial;
  position: absolute;
  text-align: center;
  background: #9796f0;
  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right,
      #fbc7d4,
      #9796f0);
  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right,
      #fbc7d4,
      #9796f0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  height: 500px;
  width: 600px;
}

.swapDiv h2 {
  margin: auto;
  line-height: 51px;
  vertical-align: bottom;
  font-family: "Fredoka", sans-serif;
}

.walletName {
  color: #1f4037;
  /* fallback for old browsers */
  color: -webkit-linear-gradient(to right,
      #99f2c8,
      #1f4037);
  /* Chrome 10-25, Safari 5.1-6 */
  color: linear-gradient(to right,
      #99f2c8,
      #1f4037);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.walletBalance {
  color: #a8ff78;
  /* fallback for old browsers */
  color: -webkit-linear-gradient(to right,
      #78ffd6,
      #a8ff78);
  /* Chrome 10-25, Safari 5.1-6 */
  color: linear-gradient(to right,
      #78ffd6,
      #a8ff78);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.walletviewimg {
  float: right;
}

.confirmHeader {
  font-family: "Fredoka", sans-serif;
  display: inline-block;
  position: relative;
}

.confirmimg {
  padding-left: 4px;
  display: inline;
}

.displayimg {
  height: 60px;
  width: 60px;
  border-radius: 30%;
}

.arrowimg {
  height: 20px;
  width: 20px;
  position: relative;
  right: 488px;
  top: 90px;
}

.itemWrap {
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Crimson+Pro&family=Fira+Sans&family=Fira+Sans+Condensed");