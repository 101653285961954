.wallet-adapter-button {
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-family: "Fira Sans";
  font-size: 12px;
  font-weight: 300;
  height: 36px;
  width: 130px;
  line-height: 36px;
  margin-bottom: 0.5px;
  box-sizing: border-box;
  background: none;

}

.wallet-adapter-button-trigger {
  border: none;
    background: none;
    color: black;
    cursor: pointer;  position: sticky;
}

button.wallet-adapter-button-trigger:hover{
  border: none;
  background: none;
  cursor: pointer;  position: sticky;
  color: gray;
}

.wallet-adapter-button:not([disabled]):focus-visible {
  outline-color: black;
} 


.wallet-adapter-button[disabled] {
  cursor: not-allowed;
}

.wallet-adapter-button-end-icon,
.wallet-adapter-button-start-icon,
.wallet-adapter-button-end-icon img,
.wallet-adapter-button-start-icon img {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.wallet-adapter-button-end-icon {
  margin-left: 8px;
}

.wallet-adapter-button-start-icon {
  margin-right: 8px;
}

.wallet-adapter-dropdown {
  position: relative;
  display: inline-block;
  font-family: "Fira Sans";
  font-weight: 300;
  z-index: 99;
  border: none;
}

.wallet-adapter-dropdown-list {
  position: absolute;
  z-index: 99;
  display: grid;
  grid-template-rows: 1fr;
  grid-row-gap: 10px;
  padding: 10px;
  top: 100%;
  right: 0;
  margin: 0;
  list-style: none;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 200ms ease, transform 200ms ease, visibility 200ms;
}

.wallet-adapter-dropdown-list-active {
  opacity: 1;
  visibility: visible;
  transform: translateY(10px);
  background-color: white;
}

.wallet-adapter-dropdown-list-item {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  white-space: nowrap;
  box-sizing: border-box;
  padding: 0 20px;
  width: 100%;
  border-radius: 6px;
  font-size: 12px;
  font-weight: 300;
  height: 24px;
}

.wallet-adapter-dropdown-list-item:not([disabled]):hover {
  background-image: linear-gradient(rgba(0, 0, 0, 0.15) 0 0);
}

.wallet-adapter-dropdown {
  position: fixed !important;
  top: 10px;
  right: 110px !important;
  width: 170px !important;
  white-space: nowrap;
  border: none;
}

.wallet-button-connect {
  position: fixed !important;
  top: 10px;
  right: 10px !important;
  width: 130px !important;
  border: none;
  background: none;
  cursor: pointer;
  color: black;
}

.wallet-button-connected {
  position: fixed !important;
  top: 10px;
  right: 10px !important;
  width: 130px !important;
  border: none;
  background: none;
  cursor: pointer;
  color: black;
}

button.wallet-button-connect:hover{
  border: none;
  background: none;
  cursor: pointer;  position: sticky;
  color: gray;
}

button.wallet-button-connected:hover{
  border: none;
  background: none;
  cursor: pointer;  position: sticky;
  color: gray;
}

