.load-portfolio-textfield,
.load-portfolio-button,
.add-amount-input,
.add-to-pool-button,
.smallText,
.window-title,
.aka-text {
  font-family: "Fira Sans";
}


.load-portfolio-textfield {
  resize: none;
  height: 24px;
  font-size: 18px;
  font-weight: lighter;
  width: calc(100% - 110px);
  border-width: 0 0 2px;
  margin-top: 23px;
  border-color: gray;
}

.load-portfolio-button {
  font-size: 24px;
  color: grey;
  border-radius: 0;
  background-color: #FFF;
  border: 0;
  float: right;
  width: 90px;
  padding-left: 10px;
}

h2.load-portfolio-button:hover {
  color: black;
  cursor: pointer;
}

::-webkit-scrollbar {
  width: 0;
  border-radius: 4px;
}

.items-grid {
  display: flex;
  padding-top: 4%;
  padding-bottom: 4%;
  width: 100%;
}

.popup-field-div {
  background: white;
  outline: 0.2px solid grey;
}

.add-amount-input {
  resize: none;
  height: 20px;
  font-size: 18px;
  font-weight: lighter;
  width: 80%;
  border-width: 0.1 0.1 0.1px;
  margin-top: 10px;
  position: relative;
  margin-left: 18px;
  border-color: gray;
}

.add-to-pool-popup-content {
  width: 200px;
  height: 200px;
}

.add-to-pool-button {
  height: 30px;
  font-size: 18px;
  margin-top: 10px !important;
  margin-left: 35px !important;
  margin-bottom: 10px !important;
  color: grey;
}

h2.add-to-pool-button:hover {
  color: black;
  cursor: pointer;
}

.smallText {
  font-size: 18px;
  font-weight: 300;
}

.window-title {
  font-size: 25px;
  font-weight: 500;
}

.aka-text {
  font-size: 18px;
  font-weight: 500;
  padding-right: 10px;
}

.user-wrapper {
  height: calc(100vh - 330px);
  width: 100%;
  margin-left: 5%;
}

.asset-load {
  width: 100%;
  height: calc((100% - 82px) / 2 + 82px);
  /* min-height: calc(25% + 200px); */
}

.swap-pool {
  width: 100%;
  margin-top: 10px;
  height: calc((100% - 82px) / 2);
}

.swap-pool-only {
  height: calc(100% - 30px);
}

.portfolio-grid {
  width: 90%;
  margin-top: 15px;
  height: calc(100% - 110px);
  /* min-height: 200px; */
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.swap-pool-grid {
  height: calc(100% - 50px);
}

.empty-tag {
  color: gray;
}