h1 {
  margin-block-end: 20px;
}

.helppage-small {
  font-weight: 200;
  font-family: "Fira Sans Condensed";
  font-size: 30px;
  padding-left: 20px;
  margin-block-end: 0px;
}