.dialog-div {
    padding: 20px 20px;
    font-family: "Fira Sans";
}

.dialog-title {
    font-size: 35px;
    font-weight: 600;
    padding-left: 0px;
}

.dialog-text {
    font-size: 20px;
    font-weight: 200;
    word-wrap: break-word;
    white-space: pre-line;
}